import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Headroom from "react-headroom-extended"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"

import "./header.scss"
import logo from "../images/shape-logo.svg"

// const Header = ({ siteTitle }) => (
//   <header
//     style={{
//       background: `rebeccapurple`,
//       marginBottom: `1.45rem`,
//     }}
//   >
//     <div
//       style={{
//         margin: `0 auto`,
//         maxWidth: 960,
//         padding: `1.45rem 1.0875rem`,
//       }}
//     >
//       <h1 style={{ margin: 0 }}>
//         <Link
//           to="/"
//           style={{
//             color: `white`,
//             textDecoration: `none`,
//           }}
//         >
//           {siteTitle}
//         </Link>
//       </h1>
//     </div>
//   </header>
// )

const Header = () => (
  <Headroom className="custom-wrapper-headroom">
    <Navbar className="nav-bar" expand="md" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            alt="Shape"
            src={logo}
            width="120px"
            className="image"
            //className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-end"
          id="responsive-navbar-nav"
        >
          <Nav className="nav-buttons custom-links-btn">
            {
              <Nav.Link as={Link} to="/measure-what-matters">
                Service
              </Nav.Link>
            }
            {
              <Nav.Link as={Link} to="/science">
                Science
              </Nav.Link>
            }
            {/* <Nav.Link as={Link} to="/esp">ESP</Nav.Link> */}
            {/* <Nav.Link as={Link} to="/features">Features</Nav.Link> */}

            {/* {
              <Nav.Link as={Link} to="/newsletter">
                Newsletter
              </Nav.Link>
            } */}

            {
              <Nav.Link as={Link} to="/board">
                Board
              </Nav.Link>
            }
            {
              <Nav.Link as={Link} to="/about">
                About
              </Nav.Link>
            }
            {
              <Nav.Link as={Link} to="/blog">
                Blog
              </Nav.Link>
            }
          </Nav>
          <Nav className="nav-buttons ml-auto">
            {
              <Nav.Link
                href="https://app.mysha.pe/"
                target="_blank"
                className="login"
              >
                Sign in
              </Nav.Link>
            }

            <Nav.Link
              eventKey={2}
              as={Link}
              to="/demo"
              // href="/pilot"
              // as={Link}
              // target="_blank"
              className="login-button"
            >
              Demo
            </Nav.Link>
            <Nav.Link
              eventKey={3}
              as={Link}
              to="/demo"
              // target="_blank"
              // href="/pilot"
              className="login-button--mobile"
            >
              Demo
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </Headroom>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
