import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Link from "gatsby-link"

import { FaLinkedinIn } from "react-icons/fa"

import spokes from "../images/shape-logo-footer.png"
import Shape from "../images/footer-shapelogo.svg"
import IsoA from "../images/9001.svg"
import IsoB from "../images/27001.svg"
import Iso from "../images/iso.svg"
import "./footer.module.scss"

const Footer = () => {
  return (
    <footer styleName="footer">
      <Container fluid styleName="footerCustomContainer">
        <Row>
          <Col lg={{ span: 12 }} styleName="footer__links">
            <Row>
              <Col xs={{ span: 12 }} lg={{ span: 3 }}>
                <h5>Follow us</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/shapeglobal/"
                      target="_blank"
                    >
                      Linkedin
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href="https://www.linkedin.com/company/shapeglobal/"
                      target="_blank"
                    >
                      Twitter
                    </a>
                  </li> */}
                </ul>
              </Col>

              <Col xs={{ span: 12 }} lg={{ span: 3 }}>
                <h5>Resources</h5>
                <ul>
                  <li>
                    <Link to="/white-paper">White Papers</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blogs</Link>
                  </li>
                  {/*<li>FAQs</li>*/}
                </ul>
              </Col>
              <Col xs={{ span: 12 }} lg={{ span: 3 }}>
                <h5>Pages</h5>
                <ul>
                  <li>
                    <Link to="http://shape.st/">SHAPE Stories</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>

                  <li>
                    <Link to="/dataprivacy">Data Privacy</Link>
                  </li>
                  <li>
                    <a
                      href="http://shape.wiki/index.php?title=FAQs"
                      target="_blank"
                    >
                      FAQs
                    </a>
                  </li>
                  {/*<li>Pricing</li>*/}
                </ul>
              </Col>
              <Col
                styleName="footer__address"
                xs={{ span: 12 }}
                lg={{ span: 3 }}
              >
                <h5>Contact Us</h5>
                <ul>
                  <li>
                    <a styleName="sendMessage" href="/contact">
                      Send us a message
                    </a>
                  </li>
                  <br />
                  <li styleName="address-title">
                    <b>UK Office:</b>
                  </li>
                  <li styleName="address-desc">5 Ivegate, Leeds,</li>
                  <li styleName="address-desc"> LS19 7RE, UK</li>
                  <li styleName="address-desc">+44 113 834 7606</li>
                  <br />
                  <li styleName="address-title">
                    <b>Australian Office:</b>
                  </li>
                  <li styleName="address-desc">Level 6, 32 Martin Place,</li>
                  <li styleName="address-desc">Sydney, NSW, 2000</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <div styleName="footer__widget">
            <img src={Shape} alt="SHAPE Logo" width="70px" />
            <h3>
              <span>
                <b>SHAPE Global Ltd.</b>
              </span>
              <br />
              System for Health, Attendance, Producitivity and Engagement
            </h3>
            <div styleName="isoParent">
              <img src={Iso} />
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
